<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <form action="#" @submit.prevent="UpdateProduct">
        <div class="row ">
            <div class="col-lg-12 col-xl-12">
                 <div class="card">
                     <div class="card-body">
                         <div class="row">
                             <div class="col-lg-12">
                                 <div class="float-sm-left">
                                     <router-link
                                             to="/products"
                                             class="btn btn-info mb-2"
                                     ><i class="mdi mdi-arrow-left-circle-outline mr-1"></i> Back
                                     </router-link
                                     >
                                 </div>
                             </div>
                         </div>

                         <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                             <i class="mdi mdi-account-circle mr-1"></i> Drug details
                         </h5>

                         <div class="row mb-3">
                             <div class="col-lg-6">
                                 <div class="form-group mb-3">
                                     <label for="name">Drug name</label>
                                     <input
                                             class="form-control"
                                             v-model="product.name"
                                             type="text"
                                             id="name"
                                             name="name"
                                             placeholder="Enter product name"
                                             v-validate="'required'"
                                             :class="{'is-invalid': errors.has('name') }"
                                     />
                                     <span v-show="errors.has('name')" class="help text-danger">{{ errors.first('name') }}</span>
                                 </div>
                             </div>
                             <div class="col-lg-6">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label for="costPrice">Cost price</label>
                                             <input
                                                     id="costPrice"
                                                     class="form-control"
                                                     v-model="product.costPrice"
                                                     type="text"
                                                     name="costPrice"
                                                     placeholder="Enter cost price"
                                                     v-validate="'required|decimal'"
                                                     :class="{'is-invalid': errors.has('costPrice') }"
                                                     step="any"
                                             />
                                             <span v-show="errors.has('costPrice')" class="help text-danger">{{ errors.first('costPrice') }}</span>
                                         </div>
                                     </div>
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label for="sellingPrice">Selling price</label>
                                             <input
                                                     class="form-control"
                                                     v-model="product.sellingPrice"
                                                     type="text"
                                                     id="sellingPrice"
                                                     name="sellingPrice"
                                                     placeholder="Enter selling price"
                                                     v-validate="'required|decimal'"
                                                     :class="{'is-invalid': errors.has('sellingPrice') }"
                                                     step="any"
                                             />
                                             <span v-show="errors.has('sellingPrice')" class="help text-danger">{{ errors.first('sellingPrice') }}</span>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-6">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label for="quantity">Quantity</label>
                                             <input
                                                     id="quantity"
                                                     class="form-control"
                                                     v-model="product.quantity"
                                                     type="number"
                                                     name="quantity"
                                                     placeholder="Enter quantity"
                                                     v-validate="'required'"
                                                     :class="{'is-invalid': errors.has('quantity') }"
                                             />
                                             <span v-show="errors.has('quantity')" class="help text-danger">{{ errors.first('quantity') }}</span>
                                         </div>
                                     </div>
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label for="sellingPrice">Discount (%)</label>
                                             <input
                                                     class="form-control"
                                                     v-model="product.discount"
                                                     type="number"
                                                     id="discount"
                                                     name="discount"
                                                     placeholder="Enter discount"
                                                     v-validate="'required'"
                                                     :class="{'is-invalid': errors.has('discount') }"
                                             />
                                             <span v-show="errors.has('discount')" class="help text-danger">{{ errors.first('discount') }}</span>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-lg-6">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label>Expiry Date</label>
                                             <date-picker v-model="product.expiryDate" :first-day-of-week="1" lang="en"
                                                          v-validate="'required'"
                                             />
                                             <span v-show="errors.has('expiryDate')" class="help text-danger">{{ errors.first('expiryDate') }}</span>
                                         </div>
                                     </div>
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label>Select supplier</label>
                                             <select class="form-control" v-model="product.supplierId" name="supplierId" id="isBestPrice" v-validate="'required'" >
                                                 <option v-for="sup in suppliersList" :value="sup.id" :key="sup.id" selected>{{sup.name}}</option>
                                             </select>
                                             <span v-show="errors.has('supplierId')" class="help text-danger">{{ errors.first('supplierId') }}</span>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <div class="col-lg-6">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label>Select Category</label>
<!--                                             <select class="form-control" v-model="product.parentCategoryId" name="parentCategoryId" id="parentCategoryId" @change="GetSubCategoriesForTheSelectedCategory(product.parentCategoryId)"  >-->
<!--                                                 <option v-for="cat in categoryList" :value="cat.id" :key="cat.id">{{cat.categoryName}}</option>-->
<!--                                             </select>-->
                                             <input
                                                     class="form-control"
                                                     v-model="product.category"
                                                     type="text"
                                                     id="categoryId"
                                                     name="categoryId"
                                                     placeholder="Search category by category name"
                                                     @input="debouncedSearchForCategory"
                                             />
                                             <div class="category-list-container" v-if="!product.category">
                                                 <ul class="list-group">
                                                     <li class="list-group-item" v-for="cat in categoryList" :key="cat.id"><span style="cursor: pointer;" @click="getSelectedCategory(cat)">{{cat.categoryName}}</span></li>
                                                 </ul>
                                             </div>
                                         </div>
                                     </div>
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                                 <label for="tag">Drug tags</label>
                                                 <input
                                                         class="form-control"
                                                         v-model="product.tag"
                                                         type="text"
                                                         id="tag"
                                                         name="tag"
                                                         placeholder="Enter product tags"
                                                         v-validate="'required'"
                                                         :class="{'is-invalid': errors.has('tag') }"
                                                 />
                                                 <span v-show="errors.has('tag')" class="help text-danger">{{ errors.first('tag') }}</span>
                                         </div>
                                     </div>
                                 </div>

                             </div>
                             <div class="col-lg-6">
                                 <div class="row">
                                     <div class="col-lg-6">
                                         <div class="form-group mb-3">
                                             <label for="dosage">Dosage (mg) / Tab</label>
                                             <input
                                                     class="form-control"
                                                     v-model="product.dosage"
                                                     type="number"
                                                     id="dosage"
                                                     name="dosage"
                                                     placeholder="Enter dosage in mg per tab"
                                                     v-validate="'required'"
                                                     :class="{'is-invalid': errors.has('dosage') }"
                                             />
                                             <span v-show="errors.has('dosage')" class="help text-danger">{{ errors.first('dosage') }}</span>
                                         </div>
                                     </div>
                                     <div class="col-lg-6">
                                               <div class="form-group mb-3">
                                                   <label for="noOfTabs">Number of tablet</label>
                                                   <input
                                                           class="form-control"
                                                           v-model="product.noOfTabs"
                                                           type="number"
                                                           id="noOfTabs"
                                                           name="noOfTabs"
                                                           placeholder="Enter number of tabs"
                                                           v-validate="'required'"
                                                           :class="{'is-invalid': errors.has('noOfTabs') }"
                                                   />
                                                   <span v-show="errors.has('noOfTabs')" class="help text-danger">{{ errors.first('noOfTabs') }}</span>
                                               </div>
                                           </div>
                                     </div>
                                 </div>

                             </div>

                         </div>

                     </div>

                 </div>
            </div>
        </div>

          <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-lg-12">
                          <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                              <i class="mdi mdi-alpha-i-circle-outline mr-1"></i> Drug Description(s)
                          </h5>

                          <div class="col-lg-12 mb-4">

                              <b-tabs justified nav-class="nav-tabs nav-bordered" >
                                  <b-tab active>
                                      <template v-slot:title>
                                          <i class="mdi mdi-settings-outline mr-1"></i>Description
                                      </template>
                                      <div>
                                          <ckeditor v-model="product.description" :editor="editor"></ckeditor>
                                      </div>
                                      <!-- end tab-pane -->
                                  </b-tab>
                                  <b-tab>
                                      <template v-slot:title>
                                          <i class="mdi mdi-settings-outline mr-1"></i>Key Benefits
                                      </template>
                                      <ckeditor v-model="product.keyBenefits" :editor="editor"></ckeditor>
                                  </b-tab>
                                  <b-tab>
                                      <template v-slot:title>
                                          <i class="mdi mdi-settings-outline mr-1"></i>Direction of use
                                      </template>
                                      <ckeditor v-model="product.directionOfUse" :editor="editor"></ckeditor>
                                  </b-tab>
                              </b-tabs>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        <div class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                        <i class="mdi mdi-alpha-i-circle-outline mr-1"></i> Drug Images
                    </h5>
                    <div class="row">
                        <div class="col-lg-12 mb-5">
                            <div class="table-responsive mb-0">
                                <table class="table table-borderless mb-0">
                                    <thead class="thead-light">
                                    <tr>
                                        <th>Index</th>
                                        <th>Image Type</th>
                                        <th>Image</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr v-for="(image,index) in additionalImages" :key="index">
                                          <td>{{index + 1}}</td>
                                         <td><span class="p-1 badge">{{ index === 0 ? 'Main image' : ' Additional Image' }}</span></td>
<!--                                          <td><img :src="image.imageUrl" width="50" height="50" /></td>-->
                                          <td><enlargeable-image :src="image.imageUrl" :src_large="image.imageUrl" class="resizable-image" /></td>
                                        <td><button type="button" v-if="product.image" @click="DeleteExistingImage(image.fileId)" class="btn btn-danger mb-1"> Delete </button></td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div>
                                <lottie-loader :loading="showLoader" />
                            </div>
                            <div class="row">
                                <div class="col-lg-7">
                                    <VueFileAgent
                                            :deletable="true"
                                            ref="vueFileAgent"
                                            v-model="selectedFiles"
                                            @select="filesSelected($event)"
                                            @beforedelete="onBeforeDelete($event)"
                                            @delete="fileDeleted($event)"
                                            :maxFiles="1"
                                            :multiple="true"
                                            :errorText="{
                                          type: 'Invalid file type. Only images or zip Allowed',
                                          size: 'Files should not exceed 10MB in size',
                                        }"
                                    ></VueFileAgent>
                                </div>
                                <div class="col-lg-5">
                                    <button class="btn btn-primary" @click="AddImageToList" type="button" v-if="selectedFiles"> Add Image to list</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </div>

            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <h5 class="mb-3 text-uppercase bg-light text-primary rounded-lg p-2">
                            <i class="mdi mdi-alpha-i-circle-outline mr-1"></i> Other Drug Settings
                        </h5>

                        <div class="table-responsive">
                            <table class="table table-centered border mb-0">
                                <tbody>
                                <tr>
                                    <th scope="row" class="text-dark font-weight-bold">Free shipping </th>
                                    <td><switches v-model="product.freeShipping" type-bold="false" color="primary" class="ml-1 mb-0"></switches></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-dark font-weight-bold">Refundable</th>
                                    <td><switches v-model="product.refundable" type-bold="false" color="primary" class="ml-1 mb-0"></switches></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-dark font-weight-bold">Prescription only</th>
                                    <td><switches v-model="product.prescriptionOnly" type-bold="false" color="primary" class="ml-1 mb-0"></switches></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-dark font-weight-bold">Is best price</th>
                                    <td><switches v-model="product.isBestPrice" type-bold="false" color="primary" class="ml-1 mb-0"></switches></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
<!--            :disabled="additionalImages.length < 2"-->
            <button type="submit" class="btn btn-warning float-right" > Update Drug</button>
        </form>
    </Layout>
</template>

<script>
    import PageHeader from "@/components/Page-header";
    import LottieLoader from "../../../../components/LottieLoader";
    import Layout from "../../../layouts/main";
    import BaseUrl from "../../../../components/constants";
    import ToastConfigAlert from "../../../../../ToastConfig";
    import DatePicker from "vue2-datepicker";
    import EnlargeableImage from '@diracleo/vue-enlargeable-image';
    import SweetAlertConfig from "../../../../../SwalConfig";
    import SweetAlertWrapper from "../../../../../sweetAlert";

    import CKEditor from "@ckeditor/ckeditor5-vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import Vue from "vue";
    import Switches from "vue-switches";

    export default {
        name: "ViewProduct",
        components : {
            PageHeader,
            LottieLoader,
            Layout,
            DatePicker,
            ckeditor: CKEditor.component,
            EnlargeableImage,
            Switches
        },
        data() {
            return {
                showImageDeletionLoader : false,
                showLoader : false,
                selectedFiles :'',
                debounceTimeout: null,
                editor: ClassicEditor,
                additionalImages :[],
                categoryList : [],
                subCategoryList : [],
                suppliersList : [],
                selectedProduct :{},
                product : {
                    name : '',
                    description:'',
                    keyBenefits:'',
                    directionOfUse:'',
                    quantity:'',
                    descriptionTwo:'',
                    refundable :false,
                    freeShipping: false,
                    costPrice:0,
                    sellingPrice : 0,
                    discount :0,
                    parentCategoryId:'',
                    subCategoryId :'',
                    category :'',
                    supplierId:'',
                    expiryDate : new Date(),
                    isBestPrice : false,
                    catType :'',
                    tag : '',
                    dosage:0,
                    noOfTabs:0,
                    prescriptionOnly: false,
                    image :'',
                    alt :'',
                    fileId:'',

                },
                title: "View drugs",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "drug details",
                        active: true,
                    },
                ],
            }
        },
        methods : {
            GetSubCategoriesForTheSelectedCategory(selectedCategoryId){
                let selectedCategories = this.categoryList.find(c => c.id === selectedCategoryId)
                this.subCategoryList = selectedCategories.subcategories;
            },
            AddImageToList(){

                if(this.selectedFiles !== '') {
                    const imageData = {
                        file : this.selectedFiles.file,
                        name : this.product.name
                    }

                    const formData = new FormData()
                    formData.append("name", imageData.name)
                    formData.append("file", imageData.file)
                    this.showLoader = true
                    BaseUrl.AxiosBearer.post("/store/uploads/upload-single-file" , formData, {

                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data',
                        },

                    }).then((res) => {
                        this.showLoader = false
                        if (!res.data.status) {
                            console.log(" data error : ", res.data.status)
                            this.$bvToast.toast(res.data?.message, ToastConfigAlert)
                            return;
                        } else {
                            let newImageData =  this.additionalImages.push({
                                imageUrl : res.data?.payload?.fileUrl,
                                fileId : res.data?.payload?.fileId
                             })
                            console.log(" list of Images :: ", newImageData)
                            this.onBeforeDelete(this.selectedFiles)
                             this.selectedFiles = '';
                            this.additionalImages = [...this.additionalImages]
                            console.log("new Image data : ", this.additionalImages)
                        }
                    }).catch((error) => {
                        console.log("error : ", error)
                        this.showLoader = false
                        this.$bvToast.toast(error?.message , ToastConfigAlert)
                        return;
                    })

                }
            },
            DeleteExistingImage(fileId) {
                 try {
                        console.log("file Id : ", fileId)
                        let newArray =  this.additionalImages.filter(a => a.fileId !== fileId)
                       this.additionalImages = [...newArray]


                 }catch (e) {
                     this.showImageDeletionLoader = false
                     console.log(" image deletion error : ", e)
                 }
            },
            debouncedSearchForCategory(){
                clearTimeout(this.debounceTimeout);

                // Set a new timeout to execute the search after 500 milliseconds
                this.debounceTimeout = setTimeout(() => {
                    this.GetCategoryByCategoryName(this.product.category);
                }, 500);
            },
            GetCategoryByCategoryName(categoryName) {
                BaseUrl.AxiosBearer(`store/search?searchModule=CATEGORIES&classification=PHARMACY&page=1&pageSize=30&searchText=${categoryName}`).then((resp) => {
                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })

            },
            getSelectedCategory(selectedCategory){
                this.product.subCategoryId = selectedCategory.id
                this.product.category = selectedCategory.categoryName
                this.categoryList = []
            },
            UpdateProduct(){

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.sweetAlert.showDialogYesOrNo(Object.assign(SweetAlertConfig, {
                            title : "Update Product ?",
                            confirmButtonText :"Yes Update"
                        }), () => {

                            const today = new Date();
                            if(this.product.expiryDate <= today ) {
                                this.$bvToast.toast("Invalid expiry date" , ToastConfigAlert)
                                return;
                            }


                            let MainImage = {
                                imageUrl:  this.additionalImages[0].imageUrl,// res.data?.payload?.fileUrl,
                                altText: "An Image",
                                fileId  : this.additionalImages[0].fileId,
                            }

                            this.additionalImages.shift()

                            let additionalImages =   this.additionalImages.map((data) => ({
                                imageUrl: data.imageUrl,
                                fileId: data.fileId,
                                altText: "An image",
                            }))


                            //  console.log(" Expiry date : ", Vue.moment(this.product.expiryDate).format('YYYY/MM/DD'))
                                    const productData = {
                                        productId : parseInt(this.$route.params.Id),
                                        productName : this.product.name,
                                        productDescription : this.product.description,
                                        productDescriptionTwo: this.product.keyBenefits,
                                        productDescriptionThree: this.product.directionOfUse,
                                        quantity : parseInt(this.product.quantity),
                                        freeShipping : this.product.freeShipping,
                                        refundable : this.product.refundable,
                                        expiryDate :  Vue.moment(this.product.expiryDate).format('YYYY/MM/DD'),//this.product.expiryDate,
                                        costPrice : parseFloat(this.product.costPrice),
                                        sellingPrice : parseFloat(this.product.sellingPrice),
                                        categoryId : this.product.subCategoryId === '' ? this.product.parentCategoryId : this.product.subCategoryId,
                                        discountPercentage : this.product.discount,
                                        tags : this.product.tag,
                                        isBestPrice : this.product.isBestPrice,
                                        supplierId : this.product.supplierId,
                                        mainImage: MainImage,
                                        dosageInMg: parseInt(this.product.dosage),
                                        noOfTablets: parseInt(this.product.noOfTabs),
                                        additionalImages: additionalImages,
                                        featured : false,
                                        prescriptionOnly : this.product.prescriptionOnly
                                    }

                            console.log(" prescriptionOnly : ", this.product.prescriptionOnly)
                                    // UPDATE PRODUCT ENDPOINT

                                    BaseUrl.AxiosBearer.put("/store/products/edit-product", productData).then((resp) => {
                                        this.showLoader = false
                                        if(!resp.data.status) {
                                           // console.log("Fail product creation ", resp.data?.message)
                                            this.$bvToast.toast(resp.data.message , ToastConfigAlert)
                                        }
                                        else {
                                            this.sweetAlert.showMessage("Product updated!  ",resp.data.message)
                                            console.log(" product creation ", resp.data?.message)
                                            this.$router.push("/products")
                                            // this.clearControl()
                                            // this.fileDeleted(this.selectedFiles)
                                        }

                                    }).catch(({ response: error }) => {

                                        this.showLoader = false
                                        let { message } = error.data;

                                        if(typeof message == 'object') {
                                            message = message.join('\n\n')
                                        }

                                        // this.showLoader = false
                                        this.$bvToast.toast(message , ToastConfigAlert)
                                    })

                                    return;
                                // }

                            // }).catch((error) => {
                            //     this.showLoader = false
                            //     this.$bvToast.toast(error?.message , ToastConfigAlert)
                            //     return;
                            // })

                        })
                    }
                })
            },
            getCatoriesByCatType() {
                BaseUrl.AxiosBearer.get(`/store/categories/get-all-categories?classification=${this.product.catType}`).then((resp) => {

                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            getSuppliersList() {

                BaseUrl.AxiosBearer.get("/store/suppliers").then((resp) => {

                    if(resp) {
                        this.suppliersList = []
                        this.suppliersList = resp.data.payload
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            getPharmacyCategories() {
                BaseUrl.AxiosBearer(`store/categories/get-categories?classification=PHARMACY&page=1&pageSize=100`).then((resp) => {

                    if(resp) {
                        this.categoryList = []
                        this.categoryList = resp.data.payload
                        this.subCategoryList = resp.data.payload.find(category => category.id === this.product.categoryId).subcategories
                    }

                }).catch((error) => {
                    console.log("error : ", error)
                })
            },
            filesSelected: function (fileRecordsNewlySelected) {
               // console.log(" selected file : ", fileRecordsNewlySelected)
                let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            },
            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            onBeforeDelete: function (fileRecord) {
               // console.log(" on before delete : ", fileRecord)
                this.fileRecordsForUpload = []
                // this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
                this.selectedFiles = ''
            },
            fileDeleted: function (fileRecord) {
                let i = this.fileRecordsForUpload.indexOf(fileRecord);
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1);
                } else {
                    this.deleteUploadedFile(fileRecord);
                }

                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
            },
            getSelectedProductById() {
                 BaseUrl.AxiosBearer.get(`/store/products/get-product/${this.$route.params.Id}`).then((resp) =>{
                     if(resp) {

                         this.selectedProduct = resp.data.payload
                          let imageData = resp.data.payload.additionalImages.filter(x =>x.isMainImage === false)


                          this.additionalImages.push({
                               imageUrl : resp.data.payload.mainImage.imageUrl,
                               fileId : resp.data.payload.mainImage.fileId
                          })

                         imageData.forEach((data) => {
                                 this.additionalImages.push({
                                    imageUrl : data.imageUrl,
                                     fileId : data.fileId
                                 })
                            })

                          this.product.name = this.selectedProduct.productName;
                          this.product.costPrice = this.selectedProduct.costPrice;
                          this.product.sellingPrice = this.selectedProduct.sellingPrice;
                          this.product.description = this.selectedProduct?.productDescription;
                          this.product.keyBenefits = this.selectedProduct?.productDescriptionTwo;
                          this.product.directionOfUse = this.selectedProduct?.productDescriptionThree;
                          this.product.quantity = this.selectedProduct?.quantity;
                          this.product.freeShipping = this.selectedProduct?.freeShipping;
                          this.product.refundable = this.selectedProduct?.refundable;
                          this.product.isBestPrice = this.selectedProduct?.isBestPrice;
                          this.product.discount = this.selectedProduct?.discountPercentage;
                          this.product.expiryDate = new Date(`${Vue.moment(this.selectedProduct?.expiryDate).format('YYYY-MM-DD')}`);
                          this.product.catType = this.selectedProduct?.category?.classification;
                          this.product.parentCategoryId = this.selectedProduct?.category?.parentCategory?.id;
                          this.product.subCategoryId = this.selectedProduct?.category?.id;
                          this.product.category = this.selectedProduct?.category?.categoryName;
                          this.product.supplierId = this.selectedProduct?.supplier?.id;
                          this.product.dosage = this.selectedProduct?.dosageInMg;
                          this.product.noOfTabs = this.selectedProduct?.noOfTablets;
                          this.product.tag = this.selectedProduct?.tags;
                          this.product.image = this.selectedProduct?.mainImage?.imageUrl
                          this.product.alt = this.selectedProduct?.mainImage?.altText
                          this.product.fileId = this.selectedProduct?.mainImage?.fileId
                         this.product.prescriptionOnly = this.selectedProduct?.prescriptionOnly
                         // this.getPharmacyCategories()
                         this.getSuppliersList();
                     }
                 }).catch((error) =>{
                     console.log(" error : ", error)
                     this.$bvToast.toast(error, ToastConfigAlert)
                 })
            }
        },
        created() {
           this.getSelectedProductById()
            this.getPharmacyCategories()
            this.sweetAlert = new SweetAlertWrapper()
        },
        // computed : {
        //     listOfImagesWIthoutTheFirstImage() {
        //         return this.additionalImages.slice(1);
        //     }
        // }
    }
</script>

<style scoped>
    .resizable-image {
        height: 50px;
        width: 50px;
    }
    .category-list-container {
        position: relative;
    }
    .list-group {
        position: absolute;
        z-index: 1; /* Ensures that the list is on top of other elements */
        width: 100%; /* Adjust as needed */
        max-height: 200px; /* Adjust as needed */
        overflow-y: auto; /* Add a scrollbar if the list is too long */
    }

</style>